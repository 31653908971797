// @flow
export type Employee = {
  name: string,
  position: string,
  imgUrl: string,
  email: string,
  phone: string,
};
export type Employees = Array<Employee>;

export const PLACEHOLDERS = {
  WOMEN: 'placeholder_women',
  MEN: 'placeholder_men',
};

const employees: Employees = [
  {
    name: 'Maria Fredholm',
    position: 'Auktoriserad Redovisningskonsult, VD & Partner',
    imgUrl: 'maria_fredholm',
    email: 'maria@mconab.se',
    phone: '070 340 63 32',
  },
  {
    name: 'Sara Mankell',
    position: 'Auktoriserad Redovisningskonsult, Partner',
    imgUrl: 'sara_mankell',
    email: 'sara@mconab.se',
    phone: '073 345 68 27',
  },
  {
    name: 'Hanna Tomasson',
    position: 'Auktoriserad Redovisningskonsult, Partner',
    imgUrl: 'hanna_tomasson',
    email: 'hanna@mconab.se',
    phone: '070 258 34 98',
  },
  {
    name: 'Emma Blom',
    position: 'Redovisningskonsult',
    imgUrl: 'emma_blom',
    email: 'emma@mconab.se',
    phone: '073 700 27 55',
  },
  {
    name: 'Nathalie Priba',
    position: 'Auktoriserad Redovisningskonsult',
    imgUrl: 'nathalie_priba',
    email: 'nathalie@mconab.se',
    phone: '072 333 38 78',
  },
  {
    name: 'Birgitta Karlsson',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'birgitta@mconab.se',
    phone: '070 530 55 60',
  },
  {
    name: 'Patricia Bromar',
    position: 'Redovisningskonsult',
    imgUrl: 'patricia_bromar',
    email: 'patricia@mconab.se',
    phone: '073 324 75 28',
  },
  {
    name: 'Andrea Heggemann',
    position: 'Redovisningskonsult',
    imgUrl: 'andrea_heggeman',
    email: 'andrea@mconab.se',
    phone: '073 228 33 33',
  },
  {
    name: 'Nerma Karabasic',
    position: 'Redovisningskonsult',
    imgUrl: 'nerma_karabasic',
    email: 'nerma@mconab.se',
    phone: '070 453 99 46',
  },
  {
    name: 'Amanda Andreasson',
    position: 'Redovisningskonsult',
    imgUrl: 'amanda_andreasson',
    email: 'amanda@mconab.se',
    phone: '070 777 47 02',
  },
  {
    name: 'Cilla Wiksjö',
    position: 'Redovisningskonsult',
    imgUrl: 'cilla_wiksjo',
    email: 'cilla@mconab.se',
    phone: '070 934 96 43',
  },
  {
    name: 'Alexandra Burman',
    position: 'Redovisningskonsult',
    imgUrl: 'alexandra_burman',
    email: 'alexandra@mconab.se',
    phone: '070 946 24 00',
  },
  {
    name: 'Mikael Hage',
    position: 'Redovisningskonsult',
    imgUrl: 'mikael_hage',
    email: 'mikael@mconab.se',
    phone: '070 984 11 10',
  },
  {
    name: 'Johanna Simonsson',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'johanna@mconab.se',
    phone: '070 489 88 02',
  },
  {
    name: 'Johanna Bartolomé',
    position: 'Redovisningskonsult',
    imgUrl: 'placeholder_women',
    email: 'johannab@mconab.se',
    phone: '073 936 01 15',
  },
  {
    name: 'Charlie',
    position: 'PR',
    imgUrl: 'charlie_fredholm',
    email: null,
    phone: null,
  },
  {
    name: 'Lars Fredholm',
    position: 'Kontorschef',
    imgUrl: 'lars_fredholm',
    email: 'lars@mconab.se',
    phone: '073 059 45 58',
  },
];

export default employees;
