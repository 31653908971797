// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Link, graphql } from 'gatsby';
import { Trail, config, animated } from 'react-spring';
import Observer from 'react-intersection-observer';
import Rellax from 'rellax';
import { FadeUp } from '../Animations';

import EmployeeCard from '../EmployeeCard';
import ScrolledList from '../CSScrolledList';
import Button from '../Button';
import JobOpenings from '../JobOpenings';
import Footer from '../Footer';
import { navConfig } from '../Navigation';

import {
  MainHeader,
  SectionHeader,
  MainParagraph,
  P,
  GatsbyImage,
} from '../Styled';
import {
  Article,
  Section,
  ContentWrapper,
  RelativeParent,
  ContentImgBg,
  ContentImg,
  Content,
  EmployeeContainer,
  EmployeeWrapper,
  ArrowButton,
  HeaderWrapper,
  FlexSection,
  SwapOrderFlexChild,
  FlexContent,
} from './styles';

import employees, { type Employees, PLACEHOLDERS } from './employees';

type Colors = {
  colorFrom: string,
  colorTo: string,
};
type State = {
  colors: Colors,
  headerColors: Colors,
  employees: Employees,
  isIE: boolean,
};

type Props = {
  isTouch: boolean,
  data: Object,
};

export default class About extends React.Component<Props, State> {
  parallaxItems: Map<HTMLElement, {}> = new Map();
  rellaxMap: Map<HTMLElement, {}> = new Map();

  state = {
    colors: {
      colorFrom: '#585754',
      colorTo: '#F6F0DD',
    },
    headerColors: {
      colorFrom: '#312F2C',
      colorTo: '#555249',
    },
    employees: [],
    isIE: false,
  };

  componentDidMount() {
    const items = this.parallaxItems.entries();

    for (let [key, value] of items) {
      this.rellaxMap.set(key, new Rellax(key, value));
    }
    this.setState({
      isIE: /*@cc_on!@*/ false || !!document.documentMode,
    });
  }

  componentWillUnmount() {
    const controllers = this.rellaxMap.entries();
    for (let [key, value] of controllers) {
      value.destroy();
      this.rellaxMap.delete(key);
    }
  }

  componentDidUpdate() {
    const items = this.parallaxItems.entries();

    for (let [key, value] of items) {
      if (!this.rellaxMap.has(key)) {
        this.rellaxMap.set(key, new Rellax(key, value));
      }
    }
  }

  handleRef = (
    node: HTMLElement,
    options: { speed: number } = { speed: -0.5 }
  ) => {
    if (this.parallaxItems.has(node) || !node) return;

    let parent = node.parentNode;
    while (parent.parentNode) {
      if (parent.parentNode.tagName === 'SECTION') {
        parent = parent.parentNode;
        break;
      }
      parent = parent.parentNode;
    }

    this.parallaxItems.set(node, options);
    this.forceUpdate();
  };

  render() {
    const { colors, headerColors, isIE } = this.state;
    const { isTouch, data } = this.props;

    const employeeKeys = Object.keys(data);

    return (
      <Article>
        <Helmet>
          <title>Mcon | Om oss</title>
        </Helmet>
        <Section background="#fff">
          <GatsbyImage
            position="45% 100%"
            fluid={data.aboutImg.childImageSharp.fluid}
            alt="Referenser huvudbild"
            backgroundColor="#4a4844"
          />
          <Observer triggerOnce>
            {({ inView, ref }) => (
              <ContentWrapper ref={ref}>
                {inView && (
                  <RelativeParent>
                    <ContentImgBg
                      url="shape0_bg.svg"
                      ref={node =>
                        this.handleRef(node, {
                          speed: 0.5,
                        })
                      }
                    />
                    <ContentImgBg ref={this.handleRef} url="shape0.svg" />
                    <Content>
                      <FadeUp>
                        <MainHeader {...headerColors} isIE={isIE}>
                          Redovisning <br /> utöver det vanliga
                        </MainHeader>
                      </FadeUp>

                      <FadeUp delay={400}>
                        <MainParagraph>
                          MCON grundades i maj 2010 av Maria Fredholm, med en
                          vision om att skapa en
                          <strong> attraktiv redovisningsbyrå </strong>
                          utöver det vanliga. Idag sitter vi i ljusa fräscha
                          lokaler i<strong> centrala Göteborg.</strong>
                        </MainParagraph>
                      </FadeUp>
                    </Content>
                  </RelativeParent>
                )}
              </ContentWrapper>
            )}
          </Observer>
        </Section>
        <Section background={'#fff'}>
          <Observer triggerOnce>
            {({ inView, ref }) => (
              <EmployeeContainer ref={ref}>
                {inView && (
                  <FadeUp>
                    <HeaderWrapper
                      ref={node =>
                        this.handleRef(node, {
                          speed: -0.5,
                        })
                      }
                    >
                      <SectionHeader className="header" {...colors} isIE={isIE}>
                        Bra människor <br /> gör bra jobb
                      </SectionHeader>
                    </HeaderWrapper>
                  </FadeUp>
                )}

                {inView && (
                  <EmployeeWrapper
                    ref={node =>
                      this.handleRef(node, {
                        speed: 1,
                      })
                    }
                  >
                    <ScrolledList
                      isTouch={isTouch}
                      renderPrevButton={props => (
                        <ArrowButton
                          className="arrow-button"
                          reverse
                          {...props}
                        />
                      )}
                      renderNextButton={props => (
                        <ArrowButton className="arrow-button" {...props} />
                      )}
                      childrenCount={employees.length}
                      step={3}
                    >
                      <Trail
                        items={employees}
                        keys={employees.map((_, i) => i)}
                        from={{
                          opacity: 0,
                          x: 50,
                        }}
                        to={{ opacity: 1, x: 0 }}
                        config={config.slow}
                        delay={600}
                        native
                      >
                        {employee => ({ x, opacity }) => {
                          const img = data[employee.imgUrl];

                          const isPlaceholder =
                            employee.imgUrl === PLACEHOLDERS.MEN ||
                            employee.imgUrl === PLACEHOLDERS.WOMEN;

                          return (
                            <animated.div
                              style={{
                                opacity,
                                transform: x.interpolate(
                                  x => `translate3d(${x}px, 0, 0)`
                                ),
                              }}
                            >
                              <EmployeeCard
                                key={employee.name}
                                node={ref}
                                {...employee}
                                employeeImg={img.childImageSharp.fluid}
                                isPlaceholder={isPlaceholder}
                              />
                            </animated.div>
                          );
                        }}
                      </Trail>
                    </ScrolledList>
                  </EmployeeWrapper>
                )}
              </EmployeeContainer>
            )}
          </Observer>
        </Section>
        <FlexSection
          background={`linear-gradient(to right, ${colors.colorFrom} 0%, ${colors.colorTo} 150%)`}
        >
          <SwapOrderFlexChild>
            <ContentWrapper id="recruit">
              <RelativeParent>
                <ContentImgBg
                  url="gradient_underlay.svg"
                  ref={node =>
                    this.handleRef(node, {
                      speed: -0.8,
                    })
                  }
                />
                <ContentImg url="colleagues.png" ref={this.handleRef} />
              </RelativeParent>
            </ContentWrapper>
          </SwapOrderFlexChild>
          <Observer triggerOnce>
            {({ inView, ref }) => {
              const delay = 200;
              return (
                <FlexContent ref={ref}>
                  {inView && (
                    <React.Fragment>
                      <FadeUp delay={delay}>
                        <SectionHeader {...headerColors} isIE={isIE}>
                          Vi letar alltid…
                        </SectionHeader>
                      </FadeUp>
                      <FadeUp delay={delay * 2}>
                        <P margin="0 0 2.4rem 0">
                          Vad kul att du är intresserad av att jobba hos oss. Vi
                          letar alltid efter nya stjärnskott som vill vara del
                          av ett drivet och framgångsrikt team. <br />
                          <br />
                          Nedan hittar du tjänster som är lediga just nu.
                          <br /> Välkommen med din ansökan!
                        </P>
                      </FadeUp>

                      <FadeUp delay={delay * 3}>
                        <Link
                          to={navConfig.contact.url}
                          id="test-id-work-with-us-link"
                        >
                          <Button bold>Jobba med oss</Button>
                        </Link>
                      </FadeUp>

                      <FadeUp delay={delay * 4}>
                        <JobOpenings
                          colors={{ colorFrom: '#B8B5AF', colorTo: '#DCDAD2' }}
                          isIE={isIE}
                        />
                      </FadeUp>
                    </React.Fragment>
                  )}
                </FlexContent>
              );
            }}
          </Observer>
        </FlexSection>
        <Footer
          color="#fff"
          backgroundColor={`linear-gradient(to right, ${colors.colorFrom} 0%, ${colors.colorTo} 150%)`}
        />
      </Article>
    );
  }
}

export const employeesQuery = graphql`
  query {
    aboutImg: file(relativePath: { eq: "about/mainAbout.jpg" }) {
      ...fluidImage
    }
    maria_fredholm: file(
      relativePath: { eq: "about/employees/maria_fredholm.png" }
    ) {
      ...fluidImageSmall
    }
    hanna_tomasson: file(
      relativePath: { eq: "about/employees/hanna_tomasson.png" }
    ) {
      ...fluidImageSmall
    }
    sara_mankell: file(
      relativePath: { eq: "about/employees/sara_mankell.png" }
    ) {
      ...fluidImageSmall
    }
    andrea_heggeman: file(
      relativePath: { eq: "about/employees/andrea_heggeman.png" }
    ) {
      ...fluidImageSmall
    }
    alexandra_burman: file(
      relativePath: { eq: "about/employees/alexandra_burman.png" }
    ) {
      ...fluidImageSmall
    }
    cilla_wiksjo: file(
      relativePath: { eq: "about/employees/cilla_wiksjo.png" }
    ) {
      ...fluidImageSmall
    }
    mikael_hage: file(relativePath: { eq: "about/employees/mikael_hage.png" }) {
      ...fluidImageSmall
    }
    amanda_andreasson: file(
      relativePath: { eq: "about/employees/amanda_andreasson.png" }
    ) {
      ...fluidImageSmall
    }
    nerma_karabasic: file(
      relativePath: { eq: "about/employees/nerma_karabasic.png" }
    ) {
      ...fluidImageSmall
    }
    emma_blom: file(relativePath: { eq: "about/employees/emma_blom.png" }) {
      ...fluidImageSmall
    }
    nathalie_priba: file(
      relativePath: { eq: "about/employees/nathalie_priba.png" }
    ) {
      ...fluidImageSmall
    }
    charlie_fredholm: file(
      relativePath: { eq: "about/employees/charlie_fredholm.png" }
    ) {
      ...fluidImageSmall
    }
    lars_fredholm: file(
      relativePath: { eq: "about/employees/lars_fredholm.png" }
    ) {
      ...fluidImageSmall
    }
    patricia_bromar: file(
      relativePath: { eq: "about/employees/patricia_bromar.png" }
    ) {
      ...fluidImageSmall
    }
    placeholder_men: file(
      relativePath: { eq: "about/employees/placeholder_men.png" }
    ) {
      ...fluidImageSmall
    }
    placeholder_women: file(
      relativePath: { eq: "about/employees/placeholder_women.png" }
    ) {
      ...fluidImageSmall
    }
  }
`;
